:root {
  --primary-color: #06032b;
  --secondary-color: #02fefe;
  /* --background-color: #ecf0f1; */
  /* --text-color: #2c3e50; */
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #02fefe; */
  /* background-color: #07006a; */
  background-color: var(--primary-color);
  color: var(--secondary-color);
  background-image: linear-gradient(180deg, rgba(26, 15, 94, 0.833), rgba(0, 0, 0, 0.623)),
    url("./asset/images/bg.svg");
  background-position: 0 0, 0 0;
  background-size: auto, auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  width: 5px !important;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

body::-webkit-scrollbar-thumb {
  /* background-color: #dffe00 !important; */
  background: linear-gradient(180deg, rgba(26, 15, 94, .48), rgba(0,0,0,.48));
  background-size: cover;
  outline: none !important;
  border-radius: 10px !important;
}
