/* ProgressBar.css */
.custom-stepper {
  width: 100%;
}
.progress-bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.progress-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%; /* Adjust the width as needed */
}

.step {
  width: 35px;
  height: 35px;
  border: 1px solid rgb(45, 45, 45);
  /* background-color: rgb(21, 22, 25); */
  background-color: #ffffff;
  /* color: white; */
  color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1rem;
  transition: background-color 0.3s ease;
  position: relative;
}

.step.active {
  /* background-color: rgb(21, 22, 25);  */
  /* color: var(--secondary-color); */
  /* border-color: var(--secondary-color); */
  background-color: transparent;
  color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.line {
  flex-grow: 1;
  height: 5px;
  background-color: rgb(6, 23, 72);
  margin: 0 -5px; /* Adjust the margin as needed */
  transition: background-color 0.3s ease;
}

.line.active {
  /* background-color: var(--secondary-color);  */
  background-color: var(--secondary-color);
}

/* ... Other styles ... */
.step_1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: auto;
}
.step_1 > p {
  text-align: center;
  color: #ffffff;
}
.button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
}

.connect-btn-wallet-div {
  margin-top: 30px;
}

[data-rk] .ju367v9c {
  background: var(--secondary-color) !important;
  color: var(--primary-color) !important;
}

.wallet-address-div {
  width: 100%;
}
.wallet-address-div > input {
  width: 100%;
  padding: 10px;
  border: 1px solid rgb(45, 45, 45);
  background-color: rgb(21, 22, 25);
  box-shadow: none;
  color: white;
  font-size: large;
  border-radius: 8px;
}

[data-rk] .ju367v9x {
  background: var(--secondary-color) !important;
  color: var(--primary-color) !important;
}

[data-rk] .ju367vfo {
  color: var(--primary-color) !important;
}

[data-rk] .ju367vb0 {
  background: var(--rk-colors-modalBackground);
  border: 1px solid white !important;
}

[data-rk] .ju367veu {
  /* color: var(--rk-colors-accentColor); */
  color: var(--secondary-color) !important;
}

.tweet-input-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
}
.tweet-input {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.tweet-input > input {
  flex: 1;
  padding: 10px;
  border: 1px solid rgb(45, 45, 45);
  /* background-color: rgb(21, 22, 25); */
  background-color: white;
  box-shadow: none;
  /* color: white; */
  color: var(--primary-color);
  font-size: large;
  border-radius: 8px;
}

.claim-faucet {
  padding: 10px;
  cursor: pointer;
  border: 1px solid rgb(45, 45, 45);
  background-color: rgb(21, 22, 25);
  /* color: var(--secondary-color); */
  color: var(--secondary-color);
  border-radius: 8px;
  font-size: large;
  font-weight: 700;
  font-family: "Chakra Petch", sans-serif;
  text-transform: uppercase;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.claim-faucet:hover {
  /* border-color: var(--secondary-color); */
  border-color: var(--secondary-color);
}

.button-container button {
  padding: 8px 15px;
  /* border: 1px solid rgb(45, 45, 45); */
  border: 1px solid white;
  /* background-color: rgb(21, 22, 25); */
  background-color: transparent;
  color: white;
  border-radius: 8px;
  cursor: pointer;
}
.button-container button:hover {
  /* border-color: var(--secondary-color); */
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}
.button-container button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.button-container button:disabled:hover {
  border-color: rgb(45, 45, 45);
}
.tweeter_parent {
  color: white;
  margin: 10px 0;
}
.tweet_link {
  cursor: pointer;
  color: var(--secondary-color);
  text-decoration: underline;
  text-underline-offset: 8px;
  transition: all 0.2s ease;
  margin-left: 10px;
}
.tweet_link:hover {
  transform: translateY(-2px);
}

.err_msg_div {
  border: 1px solid rgb(45, 45, 45);
  background-color: rgb(21, 22, 25);
  padding: 30px;
  margin: 30px 0;
  border-radius: 8px;
  width: 100%;
}
p.error_msg {
  color: rgb(242, 104, 91);
  border-left: 2px solid rgb(242, 104, 91);
  padding-left: 10px;
}
p.tx_hash a {
  color: var(--secondary-color);
  text-underline-offset: 4px;
}
p.message {
  text-align: center;
  padding: 10px;
  color: var(--secondary-color);
  border-radius: 8px;
  font-size: large;
  letter-spacing: 2px;
  font-weight: 700;
  font-family: "Chakra Petch", sans-serif;
  text-transform: uppercase;
}

.footer {
  margin-top: auto;

  margin: auto;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border-top: 1px solid rgb(45, 45, 45);
}

.lb_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.lb_div span {
  width: 90%;
  word-break: break-word;
}
.lb_title {
  padding: 10px;
  color: var(--secondary-color);
  font-size: x-large;
  font-weight: 600;
  font-family: "Chakra Petch", sans-serif;
}
.lb_msg {
  width: 70%;
  word-break: break-word;
  padding: 10px;
  line-height: 20px;
  color: #ffffff;
  font-size: large;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}
h1.discord-title {
  font-size: 1.5rem;
  font-weight: 600;
}
.social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.social-media > svg {
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 8px;
  background-color: rgba(10, 10, 10, 0.8);
  border: 2px solid rgba(255, 255, 255, 0.18);
}
.social-media > svg:hover {
  border-color: var(--secondary-color);
}

.twitter-follow {
  width: 70%;
  word-break: break-word;
  padding: 10px;
  line-height: 20px;
  color: #ffffff;
  font-size: large;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}
.twitter-follow > a {
  text-decoration: none;
  font-size: x-large;
  margin-left: 5px;
  color: var(--secondary-color);
  font-family: "Chakra Petch", sans-serif;
}

.recaptcha {
  margin: 20px 0;
}
@media (max-width: 768px) {
  .step_1 {
    width: 100%;
  }
  .lb_div span,
  .lb_div p {
    width: 100%;
    word-break: break-word;
  }
  .lb_title {
    font-size: large;
  }
  .lb_msg {
    font-size: medium;
  }
  h1.discord-title {
    font-size: 1.2rem;
  }
  .footer {
    flex-direction: column;
    gap: 20px;
  }
}
