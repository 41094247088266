.main_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto;
  gap: 30px;
  min-height: 82vh;
}
.navbar {
  height: 10vh;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  /* color: #dffe00; */
  color: var(--secondary-color);
  font-size: 1.5rem;
  font-weight: 900;
  font-family: "Chakra Petch", sans-serif;
  text-transform: uppercase;
  padding: 0px 40px;
}

.navlogodiv{
  width: 15%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.daologo{
  width: 100%;
  height: auto;

}

.card_container {
  margin-top: 20px;
  width: 90%;
  max-width: 1000px;
  height: max-content;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(255, 255, 255, 0.18);
  padding: 30px;
  flex-direction: row;
  backdrop-filter: blur(1px);
}
.card_container h1 {
  color: #fff;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  font-size: 2.5rem;
  line-height: 120%;
  text-align: center;
  margin: 30px 0;
  /* margin-top: 30px; */

  word-break: break-word;
}
.oops {
  /* color: #dffe00; */
  color: var(--secondary-color);

  font-weight: 900;
  font-family: "Chakra Petch", sans-serif;
  font-size: 4rem;
  text-align: left;
  width: 70%;
  margin: 20px 0;

  word-break: break-word;
}
#canvas-parent {
  width: 900px;
  height: 900px;
  background-color: transparent;
  border: 1px solid red;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
canvas {
  position: absolute;
}

.social-media svg:hover{
border-color: var(--secondary-color) !important;
}
@media (max-width: 768px) {
  .card_container {
    margin-top: 20px;
    flex-direction: column-reverse;
    border: none;
    padding: 0%;
  }
  .card_container h1 {
    width: 100%;
    font-size: 1.5rem;
  }
  .oops {
    font-size: 3rem;
    text-align: left;
    width: 70%;
  }
}
